<template>
	<b-popover
		:target="target"
		triggers="click"
		:show.sync="show"
		placement="auto"
		container="my-container"
		ref="popover"
		@show="onShow"
		@cancel="onClose"
		@shown="onShown"
		@hidden="onClose"
		class="p-4"
		custom-class="template-popover"
	>
		<template #title>{{ FormMSG(1, 'Add an expression') }}</template>

		<div class="form">
			<context-menu
				:menuX="menuX"
				:menuY="menuY"
				@item:clicked="selectedArea"
				:showContextMenu="isShowContextMenu"
				title="Variables"
				:dataList="variableList"
				@on-close-context-menu="CloseContextMenu"
			/>
			<div class="w-100 d-inline-flex justify-content-between align-items-center">
				<label for="keyword-input">{{ FormMSG(2, 'Expression') }}</label>
				<label class="cursor-pointer" @click="clearInput">{{ FormMSG(3, 'Clear') }}</label>
			</div>
			<div
				:style="{
					display: 'flex',
					position: 'relative',
					height: '100%'
				}"
			>
				<b-form-textarea
					class="mb-2 form-control"
					label="keyword"
					ref="textarea"
					id="keyword-input"
					v-model="dataTextArea"
					:placeholder="FormMSG(4, 'Enter something...')"
					@click="handleClick"
					@keyup="handleKeyUp"
					size="md"
					invalid-feedback="This field is required"
					:max-rows="maxRows"
					:rows="rows"
				>
				</b-form-textarea>
			</div>
		</div>
		<b-row class="mt-2">
			<b-col sm="6">
				<div>
					<b-button @click="onClose" size="sm" variant="outline-secondary">{{ FormMSG(5, 'Close') }}</b-button>
				</div>
			</b-col>
			<b-col sm="6" class="d-flex justify-content-end">
				<div>
					<b-button :disabled="dataTextArea.length === 0" @click="onAddKeyWord" size="sm" variant="primary">{{ FormMSG(6, 'Save') }}</b-button>
				</div>
			</b-col>
		</b-row>
	</b-popover>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import ContextMenu from '@/components/Translation/ContextMenu';
import { getCarbonVariables } from '@/cruds/language.crud';
import { store } from '@/store';
import * as _ from 'lodash';

export default {
	name: 'FormKeyword',
	mixins: [languageMessages],
	props: {
		transtateItem: {
			type: Object,
			default: () => null,
			required: false
		},
		target: {
			type: String,
			default: ''
		},
		show: {
			type: Boolean,
			default: false
		}
	},
	components: {
		ContextMenu
	},
	data() {
		return {
			variableList: [],
			initialList: [],
			///////////////
			dataTextArea: '',
			menuX: 0,
			menuY: 0,
			windowWidth: window.innerWidth,
			maxRows: 8,
			rows: 8,
			isShowContextMenu: false,
			defaultTextArea: '',
			cursor: 0
		};
	},
	methods: {
		isCheckBalise(text) {
			const _text = text.toString();
			if (/.+<<$/gi.test(_text)) {
				if (/<<$/.test(_text)) {
					return true;
				}
				return false;
			} else {
				if (/<<$/gi.test(_text)) return true;
				return false;
			}
		},
		CloseContextMenu() {
			this.isShowContextMenu = false;
		},
		selectedArea(text) {
			const name = `${text}>>`;
			this.dataTextArea = this.dataTextArea.slice(0, this.cursor) + name + this.dataTextArea.slice(this.cursor);
			this.isShowContextMenu = false;
			this.keepFocusOnInput();
			this.$emit('variableSelected');
			this.menuX = 0;
			this.menuY = 0;
		},
		handleClick(event) {
			const textArea = this.$refs['textarea'].$el;
			const contentTextArea = event.target.value;
			const contentArrays = contentTextArea.split('\n');
			this.cursor = textArea.selectionStart;
			const indexLigne = this.getLineNumber(textArea, this.cursor);
			const text = contentArrays[indexLigne - 1];
			const balise = this.isCheckBalise(text);
			if (balise) {
				this.menuY = indexLigne * 20;
				this.isShowContextMenu = true;
			} else {
				this.isShowContextMenu = false;
			}
		},
		handleKeyUp(event) {
			const textArea = this.$refs['textarea'];
			const contentTextArea = event.target.value.toString();
			const contentArrays = contentTextArea.split('\n');
			this.cursor = textArea.selectionStart;
			const textAreaElement = textArea.$el;
			const indexLigne = this.getLineNumber(textAreaElement, this.cursor) - 1;
			let balise = false;
			if (this.cursor > 0) {
				const text = contentArrays[indexLigne].substring(0, this.cursor);
				balise = this.isCheckBalise(text);
			} else {
				balise = false;
			}
			if (balise) {
				let ligne = 0;
				let _menuY = 0;
				if (indexLigne <= this.maxRows) {
					ligne = indexLigne * 20;
					_menuY = 20;
				} else {
					ligne = this.maxRows * 20;
					_menuY = 0;
				}
				const _menuX = contentArrays[indexLigne].length + 1 || 0;
				this.menuX = _menuX * 8;
				const clientWidth = textArea.$el.clientWidth;
				const pourcentage = (clientWidth * 16) / 100;
				if (this.menuX >= clientWidth) {
					this.menuX = this.menuX - pourcentage;
				}
				if (this.menuX + pourcentage >= clientWidth) {
					this.menuX = (clientWidth * 80) / 100;
				}
				this.menuY = _menuY + ligne;
				this.isShowContextMenu = true;
			} else {
				this.isShowContextMenu = false;
			}
		},
		getLineNumber(textarea, cursorPosition) {
			const textBeforeCursor = textarea.value.slice(0, cursorPosition);
			return textBeforeCursor.split('\n').length;
		},
		clearInput() {
			this.dataTextArea = '';
			this.cursor = 0;
			this.menuX = 0;
			this.menuY = 0;
			this.isShowContextMenu = false;
		},
		onClose() {
			this.$emit('onClose');
			this.clearInput();
		},
		onAddKeyWord() {
			this.$emit('on-add-key-word', this.dataTextArea, this.transtateItem);
			this.dataTextArea = '';
		},
		onShow() {},
		onShown() {
			this.focusRef(this.$refs.textarea);
		},
		focusRef(ref) {
			this.$nextTick(() => {
				this.$nextTick(() => {
					(ref.$el || ref).focus();
				});
			});
		},
		keepFocusOnInput() {
			this.$nextTick(() => {
				const inputElement = this.$refs.textarea.$el;
				if (inputElement) {
					inputElement.focus();
				}
			});
		}
	},
	watch: {
		show: {
			handler(newVal) {
				if (_.isNil(this.transtateItem)) {
					this.dataTextArea = '';
				} else {
					this.dataTextArea = this.transtateItem.keyword;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		transtateItem: {
			handler(newVal) {
				if (newVal !== null) {
					this.dataTextArea = newVal.keyword;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	mounted() {
		getCarbonVariables().then((res) => {
			this.variableList = res.map((item) => item.name);
			this.initialList = [...this.variableList];
		});
	}
};
</script>

<style>
.popover {
	width: 500px !important;
	max-width: 500px !important;
}

#variableList {
	width: 400px;
	max-height: 200px;
	overflow-y: scroll;
}

.context-menu-keyword {
	height: 200px;
	width: 150px;
	overflow-y: scroll;
	position: absolute;
	background-color: white;
	border-radius: calc(0.3rem - 1px);
	border: 0.5px solid rgb(213, 213, 213);
	padding-top: 10px;
	padding-bottom: 10px;
	z-index: 10;
}

.context-menu-items-keyword {
	padding-left: 10px;
}

.context-menu-items-keyword:hover {
	background-color: #b8e4e3;
}

.keyword-separator {
	border-bottom: 1px solid rgb(203, 203, 203);
}
.template-popover {
	width: 800px !important;
	max-width: 800px !important;
	height: 300px !important;
}
</style>
