var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-popover",
    {
      ref: "popover",
      staticClass: "p-4",
      attrs: {
        target: _vm.target,
        triggers: "click",
        show: _vm.show,
        placement: "auto",
        container: "my-container",
        "custom-class": "template-popover",
      },
      on: {
        "update:show": function ($event) {
          _vm.show = $event
        },
        show: _vm.onShow,
        cancel: _vm.onClose,
        shown: _vm.onShown,
        hidden: _vm.onClose,
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_vm._v(_vm._s(_vm.FormMSG(1, "Add an expression")))]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "form" },
        [
          _c("context-menu", {
            attrs: {
              menuX: _vm.menuX,
              menuY: _vm.menuY,
              showContextMenu: _vm.isShowContextMenu,
              title: "Variables",
              dataList: _vm.variableList,
            },
            on: {
              "item:clicked": _vm.selectedArea,
              "on-close-context-menu": _vm.CloseContextMenu,
            },
          }),
          _c(
            "div",
            {
              staticClass:
                "w-100 d-inline-flex justify-content-between align-items-center",
            },
            [
              _c("label", { attrs: { for: "keyword-input" } }, [
                _vm._v(_vm._s(_vm.FormMSG(2, "Expression"))),
              ]),
              _c(
                "label",
                {
                  staticClass: "cursor-pointer",
                  on: { click: _vm.clearInput },
                },
                [_vm._v(_vm._s(_vm.FormMSG(3, "Clear")))]
              ),
            ]
          ),
          _c(
            "div",
            {
              style: {
                display: "flex",
                position: "relative",
                height: "100%",
              },
            },
            [
              _c("b-form-textarea", {
                ref: "textarea",
                staticClass: "mb-2 form-control",
                attrs: {
                  label: "keyword",
                  id: "keyword-input",
                  placeholder: _vm.FormMSG(4, "Enter something..."),
                  size: "md",
                  "invalid-feedback": "This field is required",
                  "max-rows": _vm.maxRows,
                  rows: _vm.rows,
                },
                on: { click: _vm.handleClick, keyup: _vm.handleKeyUp },
                model: {
                  value: _vm.dataTextArea,
                  callback: function ($$v) {
                    _vm.dataTextArea = $$v
                  },
                  expression: "dataTextArea",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", { attrs: { sm: "6" } }, [
            _c(
              "div",
              [
                _c(
                  "b-button",
                  {
                    attrs: { size: "sm", variant: "outline-secondary" },
                    on: { click: _vm.onClose },
                  },
                  [_vm._v(_vm._s(_vm.FormMSG(5, "Close")))]
                ),
              ],
              1
            ),
          ]),
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-end", attrs: { sm: "6" } },
            [
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.dataTextArea.length === 0,
                        size: "sm",
                        variant: "primary",
                      },
                      on: { click: _vm.onAddKeyWord },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(6, "Save")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }