var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-row",
        { staticClass: "container-layout px-0" },
        [
          _c("b-col", { attrs: { md: "12" } }, [
            _c(
              "h1",
              {
                class: [
                  `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                  ,
                  { "is-pwa": _vm.$isPwa() },
                ],
              },
              [_vm._v("\n\t\t\t\t" + _vm._s(this.caption1) + "\n\t\t\t")]
            ),
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", xl: "12" } },
            [
              _c(
                "b-overlay",
                {
                  attrs: { show: _vm.isBusyAllScreen, rounded: "lg" },
                  on: {
                    "update:show": function ($event) {
                      _vm.isBusyAllScreen = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "animated fadeIn" },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "card-border-blue-light",
                          attrs: { "no-body": "" },
                        },
                        [
                          _c(
                            "b-card-body",
                            { staticClass: "form content-card-collapsible" },
                            [
                              _c(
                                "fieldset",
                                {
                                  staticClass:
                                    "scheduler-border fix-fieldset-card-inside-padding-bottom",
                                  staticStyle: {
                                    "background-color": "rgb(248, 249, 251)",
                                  },
                                },
                                [
                                  _c(
                                    "legend",
                                    {
                                      staticClass:
                                        "card-inside text-color-rhapsody-in-blue-2",
                                      staticStyle: { "font-size": "1.25em" },
                                    },
                                    [_vm._v("Advanced search")]
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Choice",
                                                "label-for": "choice",
                                                "label-cols": 4,
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options: _vm.choiceOptions,
                                                  size: "md",
                                                },
                                                on: {
                                                  change: _vm.choiceChanged,
                                                },
                                                model: {
                                                  value: _vm.currentChoice,
                                                  callback: function ($$v) {
                                                    _vm.currentChoice = $$v
                                                  },
                                                  expression: "currentChoice",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "From English to",
                                                "label-for": "language",
                                                "label-cols": 4,
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  options:
                                                    _vm.optionsForLanguage,
                                                  size: "md",
                                                },
                                                on: {
                                                  change: _vm.languageChanged,
                                                },
                                                model: {
                                                  value: _vm.currentLanguage,
                                                  callback: function ($$v) {
                                                    _vm.currentLanguage = $$v
                                                  },
                                                  expression: "currentLanguage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.currentChoice == 0
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Application",
                                                    "label-for": "appChoice",
                                                    "label-cols": 4,
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    attrs: {
                                                      options: _vm.Apps,
                                                      id: "appChoice",
                                                      size: "md",
                                                    },
                                                    on: {
                                                      change: _vm.appChanged,
                                                    },
                                                    model: {
                                                      value: _vm.currentApp,
                                                      callback: function ($$v) {
                                                        _vm.currentApp = $$v
                                                      },
                                                      expression: "currentApp",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Form",
                                                    "label-for": "formChoice",
                                                    "label-cols": 4,
                                                  },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      label: "formName",
                                                      options: _vm.Forms,
                                                      reduce: (option) =>
                                                        option.formNumber,
                                                      clearable: false,
                                                    },
                                                    on: {
                                                      "option:selected":
                                                        _vm.formChanged,
                                                    },
                                                    model: {
                                                      value: _vm.currentForm,
                                                      callback: function ($$v) {
                                                        _vm.currentForm = $$v
                                                      },
                                                      expression: "currentForm",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "fieldset",
                                {
                                  staticClass:
                                    "fix-fieldset-card-inside-padding-bottom my-3 py-0",
                                  staticStyle: {
                                    "background-color": "#ffffff",
                                  },
                                },
                                [
                                  _c(
                                    "b-alert",
                                    {
                                      attrs: {
                                        show: _vm.addedMessageAlert,
                                        dismissible: "",
                                        fade: "",
                                        variant: "primary",
                                      },
                                      on: { dismissed: _vm.closeAlert },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-center align-items-center pb-2",
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("-- Last New Message --"),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center pb-2",
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pl-0",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex flex-row",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "pr-4" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                        },
                                                        [
                                                          _c("strong", [
                                                            _vm._v("Number"),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.newMessageInfo
                                                                .number
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm.currentChoice !== 2
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "pr-4" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                            },
                                                            [
                                                              _c("strong", [
                                                                _vm._v("Value"),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .newMessageInfo
                                                                    .value
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.currentChoice === 1
                                                    ? _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Factor"),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .newMessageInfo
                                                                  .factor
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-row justify-content-start align-items-center",
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              { staticClass: "text-left" },
                                              [
                                                _c("strong", [
                                                  _vm._v("Message"),
                                                ]),
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                "« " +
                                                  _vm._s(
                                                    _vm.newMessageInfo.message
                                                  ) +
                                                  " »"
                                              ),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "fieldset",
                                    {
                                      staticClass:
                                        "scheduler-border fix-fieldset-card-inside-padding-bottom py-0 mb-0",
                                    },
                                    [
                                      _c(
                                        "legend",
                                        {
                                          staticClass:
                                            "card-inside text-color-rhapsody-in-blue-2",
                                          staticStyle: {
                                            "font-size": "1.25em",
                                          },
                                        },
                                        [_vm._v("Translations")]
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pb-4",
                                              attrs: { cols: "9" },
                                            },
                                            [
                                              _c(
                                                "fieldset",
                                                {
                                                  staticClass:
                                                    "card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0",
                                                },
                                                [
                                                  _c(
                                                    "legend",
                                                    {
                                                      staticClass:
                                                        "card-inside",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "« " +
                                                          _vm._s(
                                                            _vm.newLangres
                                                              .message
                                                          ) +
                                                          " »"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  _vm.translatedLabel,
                                                                "label-for":
                                                                  "msgTranslated",
                                                                "label-cols": 2,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-textarea",
                                                                {
                                                                  class: {
                                                                    "is-invalid":
                                                                      _vm.isSubmitted &&
                                                                      _vm.$v
                                                                        .newLangres
                                                                        .translatedMessage
                                                                        .$error,
                                                                  },
                                                                  attrs: {
                                                                    type: "textarea",
                                                                    rows: "2",
                                                                    autocomplete:
                                                                      "off",
                                                                    id: "msgTranslated",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .newLangres
                                                                        .translatedMessage,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.newLangres,
                                                                          "translatedMessage",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "newLangres.translatedMessage",
                                                                  },
                                                                }
                                                              ),
                                                              _vm.isSubmitted &&
                                                              !_vm.$v.newLangres
                                                                .translatedMessage
                                                                .required
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "invalid-feedback",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tMessage is required\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c("b-col", {
                                                        attrs: { cols: "2" },
                                                      }),
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-row",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-content-end align-items-center",
                                                            },
                                                            [
                                                              _c(
                                                                "b-col",
                                                                {
                                                                  attrs: {
                                                                    cols: _vm.numberCols,
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-group",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Number",
                                                                        "label-for":
                                                                          "resNumber",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              type: "number",
                                                                              id: "resNumber",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .newLangres
                                                                                  .number,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.newLangres,
                                                                                    "number",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "newLangres.number",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm.currentChoice !==
                                                              2
                                                                ? _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: _vm.valueCols,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Value",
                                                                              "label-for":
                                                                                "resValue",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "number",
                                                                                  id: "resValue",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newLangres
                                                                                      .value,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newLangres,
                                                                                        "value",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newLangres.value",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.currentChoice ===
                                                              1
                                                                ? _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: _vm.factorCols,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Factor",
                                                                              "label-for":
                                                                                "factor",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "number",
                                                                                  id: "factor",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newLangres
                                                                                      .paramFloat,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newLangres,
                                                                                        "paramFloat",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newLangres.paramFloat",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.currentChoice ===
                                                                1 &&
                                                              _vm.showAIKeywords
                                                                ? _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: _vm.priorityCols,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-group",
                                                                        [
                                                                          _c(
                                                                            "template",
                                                                            {
                                                                              slot: "label",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex flex-row justify-content-start align-items-center gap-4",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "label",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          for: "priority",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Priority"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-color-blue-streak",
                                                                                      attrs:
                                                                                        {
                                                                                          id: "priority-tooltip",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        _vm.getLucideIcon(
                                                                                          "Info"
                                                                                        ),
                                                                                        {
                                                                                          tag: "component",
                                                                                          attrs:
                                                                                            {
                                                                                              size: 16,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "b-tooltip",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          target:
                                                                                            "priority-tooltip",
                                                                                          "custom-class":
                                                                                            "custom-tooltip",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "priority-custom-tooltip p-1",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tKeywords are processed from lowest to highest priority value (0 first,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t100 last)\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "b-form-input",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  type: "number",
                                                                                  id: "priority",
                                                                                  max: 1000,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .newLangres
                                                                                      .priority,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.newLangres,
                                                                                        "priority",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "newLangres.priority",
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.currentChoice ===
                                                              1
                                                                ? _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: _vm.helpCols,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn-transparent text-color-rhapsody-in-blue",
                                                                          attrs:
                                                                            {
                                                                              id: "show-modal",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                _vm.showModal = true
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              "Info"
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "#225CBD",
                                                                                  size: 22,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-100 d-flex flex-row justify-content-end pb-3",
                                                        },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "outline-secondary",
                                                                    block: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.newMessage,
                                                                  },
                                                                },
                                                                [_vm._v("New")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "3",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-button",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "primary",
                                                                    disabled:
                                                                      _vm.disableSave,
                                                                    block: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.curLangresUpd,
                                                                  },
                                                                },
                                                                [_vm._v("Save")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "3" } },
                                            [
                                              _c(
                                                "fieldset",
                                                {
                                                  staticClass:
                                                    "card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0",
                                                },
                                                [
                                                  _c(
                                                    "legend",
                                                    {
                                                      staticClass:
                                                        "card-inside",
                                                    },
                                                    [_vm._v("Google")]
                                                  ),
                                                  _c(
                                                    "b-row",
                                                    { staticClass: "pb-4" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb pb-2",
                                                              attrs: {
                                                                size: "lg",
                                                                value: "all",
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.selectGoogleTranslation,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.valueGoogleSelected,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.valueGoogleSelected =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "valueGoogleSelected",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tAll items in all Languages\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "b-form-checkbox",
                                                            {
                                                              staticClass:
                                                                "pj-cb pb-3",
                                                              attrs: {
                                                                size: "lg",
                                                                value:
                                                                  _vm.currentLanguage,
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.selectGoogleTranslation,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.valueGoogleSelected,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.valueGoogleSelected =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "valueGoogleSelected",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "All items in " +
                                                                  _vm._s(
                                                                    _vm.languageChosen
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-100 d-flex flex-row justify-content pb-3",
                                                      staticStyle: {
                                                        "padding-top": "41px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                disabled:
                                                                  _vm.disableGoogleTranslation,
                                                                block: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.doTranslation(
                                                                      _vm.valueGoogleSelected
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tTranslate\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "fieldset",
                                {
                                  staticClass:
                                    "scheduler-border fix-fieldset-card-inside-padding-bottom pt-0 pb-3",
                                  staticStyle: {
                                    "background-color": "rgb(248, 249, 251)",
                                  },
                                },
                                [
                                  _c(
                                    "legend",
                                    {
                                      staticClass:
                                        "card-inside text-color-rhapsody-in-blue-2",
                                      staticStyle: { "font-size": "1.25em" },
                                    },
                                    [_vm._v("Filters")]
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-1",
                                          attrs: { cols: "8" },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                label: "Per page",
                                                "label-for": "per-page-select",
                                                "label-cols-sm": "2",
                                                "label-cols-md": "2",
                                                "label-cols-lg": "2",
                                                "label-size": "sm",
                                              },
                                            },
                                            [
                                              _c("b-form-select", {
                                                attrs: {
                                                  id: "per-page-select",
                                                  options: _vm.pageOptions,
                                                  size: "sm",
                                                },
                                                on: {
                                                  change: _vm.changeNumberRows,
                                                },
                                                model: {
                                                  value: _vm.perPage,
                                                  callback: function ($$v) {
                                                    _vm.perPage = $$v
                                                  },
                                                  expression: "perPage",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.currentChoice === 1
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  staticClass: "mb-3",
                                                  attrs: {
                                                    label: "Menu number",
                                                    "label-cols-sm": "2",
                                                    "label-cols-md": "2",
                                                    "label-cols-lg": "2",
                                                    "label-align-sm": "left",
                                                    "label-size": "sm",
                                                    "label-for": "number-res",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          disabled:
                                                            _vm.disableMenuNumber,
                                                          id: "number-res",
                                                          type: "number",
                                                          step: "1",
                                                          min: "0",
                                                          placeholder: "0",
                                                        },
                                                        on: {
                                                          keyup: [
                                                            _vm.handleChangeResInit,
                                                            function ($event) {
                                                              if (
                                                                !$event.type.indexOf(
                                                                  "key"
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter",
                                                                  13,
                                                                  $event.key,
                                                                  "Enter"
                                                                )
                                                              )
                                                                return null
                                                              return _vm.handleChangeRes.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          ],
                                                        },
                                                        model: {
                                                          value: _vm.numberRes,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.numberRes = $$v
                                                          },
                                                          expression:
                                                            "numberRes",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            {
                                                              staticClass:
                                                                "btn-search",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.disableMenuNumber,
                                                              },
                                                            },
                                                            [
                                                              _vm.numberRes ===
                                                              0
                                                                ? _c(
                                                                    _vm.getIconLucide(
                                                                      "Search"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "icon",
                                                                      attrs: {
                                                                        color:
                                                                          "#FFFFFF",
                                                                        size: 16,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    _vm.getIconLucide(
                                                                      "X"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "icon",
                                                                      attrs: {
                                                                        color:
                                                                          "#FFFFFF",
                                                                        size: 16,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.clearResNumber,
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "b-tooltip",
                                                            {
                                                              attrs: {
                                                                target:
                                                                  "number-res",
                                                                placement:
                                                                  "top",
                                                                triggers:
                                                                  "focus",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tType 'Enter' to launch search\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-form-group",
                                            {
                                              staticClass: "mb-3",
                                              attrs: {
                                                label: "Filter",
                                                "label-cols-sm": "2",
                                                "label-cols-md": "2",
                                                "label-cols-lg": "2",
                                                "label-align-sm": "left",
                                                "label-size": "sm",
                                                "label-for": "filterInput",
                                              },
                                            },
                                            [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.focus",
                                                        modifiers: {
                                                          focus: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      type: "text",
                                                      id: "filterInput",
                                                      placeholder:
                                                        "Type to search...",
                                                      autocomplete: "off",
                                                    },
                                                    on: {
                                                      keydown: function (
                                                        $event
                                                      ) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "enter",
                                                            13,
                                                            $event.key,
                                                            "Enter"
                                                          )
                                                        )
                                                          return null
                                                        return _vm.search(
                                                          $event
                                                        )
                                                      },
                                                      keyup: function ($event) {
                                                        if (
                                                          !$event.type.indexOf(
                                                            "key"
                                                          ) &&
                                                          _vm._k(
                                                            $event.keyCode,
                                                            "delete",
                                                            [8, 46],
                                                            $event.key,
                                                            [
                                                              "Backspace",
                                                              "Delete",
                                                              "Del",
                                                            ]
                                                          )
                                                        )
                                                          return null
                                                        return _vm.removeFilter.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: _vm.filter,
                                                      callback: function ($$v) {
                                                        _vm.filter =
                                                          typeof $$v ===
                                                          "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                      },
                                                      expression: "filter",
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    {
                                                      staticClass:
                                                        "cursor-pointer",
                                                    },
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticClass:
                                                            "btn-search",
                                                        },
                                                        [
                                                          _vm.filter.length ===
                                                          0
                                                            ? _c(
                                                                _vm.getIconLucide(
                                                                  "Search"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  staticClass:
                                                                    "icon",
                                                                  attrs: {
                                                                    color:
                                                                      "#FFFFFF",
                                                                    size: 16,
                                                                    "stroke-width": 2.5,
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                _vm.getIconLucide(
                                                                  "X"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  staticClass:
                                                                    "icon",
                                                                  attrs: {
                                                                    color:
                                                                      "#FFFFFF",
                                                                    size: 16,
                                                                    "stroke-width": 2.5,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.clearFilter,
                                                                  },
                                                                }
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "filterInput",
                                                            placement: "top",
                                                            triggers: "focus",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tType 'Enter' to launch search\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.showAIKeywords
                                            ? _c(
                                                "b-form-group",
                                                {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    label: "Filter Category",
                                                    "label-cols-sm": "2",
                                                    "label-cols-md": "2",
                                                    "label-cols-lg": "2",
                                                    "label-align-sm": "left",
                                                    "label-size": "sm",
                                                    "label-for": "filterInput",
                                                  },
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      placeholder:
                                                        "Select a carbon category",
                                                      options:
                                                        _vm.carbonListWithReference,
                                                      label: "message",
                                                    },
                                                    on: {
                                                      search:
                                                        _vm.filterCategories,
                                                      input:
                                                        _vm.handleSelectedOption,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedCategory,
                                                      callback: function ($$v) {
                                                        _vm.selectedCategory =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedCategory",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex flex-column justify-content-center align-items-end",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb mt-1",
                                              attrs: { size: "lg" },
                                              on: {
                                                change:
                                                  _vm.getShowAllMessagesToVerify,
                                              },
                                              model: {
                                                value:
                                                  _vm.showAllMessagesToVerify,
                                                callback: function ($$v) {
                                                  _vm.showAllMessagesToVerify =
                                                    $$v
                                                },
                                                expression:
                                                  "showAllMessagesToVerify",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Show all messages to verify"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.langresType ===
                                            _vm.LANG_RES_TYPE.menu && _vm.isDev
                                            ? _c(
                                                "b-form-checkbox",
                                                {
                                                  staticClass: "pj-cb mt-1",
                                                  attrs: { size: "lg" },
                                                  on: {
                                                    change:
                                                      _vm.getShowAIkeywords,
                                                  },
                                                  model: {
                                                    value: _vm.showAIKeywords,
                                                    callback: function ($$v) {
                                                      _vm.showAIKeywords = $$v
                                                    },
                                                    expression:
                                                      "showAIKeywords",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "2px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Display AI key words"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        xl: `${_vm.showAIKeywords ? 7 : 12}`,
                                      },
                                    },
                                    [
                                      _c(
                                        "fieldset",
                                        {
                                          staticClass:
                                            "scheduler-border fix-fieldset-card-inside-padding-bottom py-0 mb-0",
                                          staticStyle: {
                                            "background-color":
                                              "rgb(248, 249, 251)",
                                          },
                                        },
                                        [
                                          _c(
                                            "legend",
                                            {
                                              staticClass:
                                                "card-inside text-color-rhapsody-in-blue-2",
                                              staticStyle: {
                                                "font-size": "1.25em",
                                              },
                                            },
                                            [_vm._v("Translation records")]
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "6", xl: "6" },
                                                },
                                                [
                                                  _c("b-pagination", {
                                                    staticClass: "mt-1 mb-2",
                                                    attrs: {
                                                      "total-rows":
                                                        _vm.totalRows,
                                                      "per-page": _vm.perPage,
                                                      align: "fill",
                                                      size: "md",
                                                    },
                                                    on: {
                                                      change: _vm.onPaginated,
                                                    },
                                                    model: {
                                                      value: _vm.currentPage,
                                                      callback: function ($$v) {
                                                        _vm.currentPage = $$v
                                                      },
                                                      expression: "currentPage",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: { cols: "3", xl: "3" },
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: "Page n°",
                                                        "label-for":
                                                          "page-number",
                                                        "label-cols": 4,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "number",
                                                          id: "page-number",
                                                          min: "0",
                                                          step: "1",
                                                        },
                                                        on: {
                                                          keydown: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              !$event.type.indexOf(
                                                                "key"
                                                              ) &&
                                                              _vm._k(
                                                                $event.keyCode,
                                                                "enter",
                                                                13,
                                                                $event.key,
                                                                "Enter"
                                                              )
                                                            )
                                                              return null
                                                            return _vm.goToPage(
                                                              $event
                                                            )
                                                          },
                                                        },
                                                        model: {
                                                          value: _vm.pageNumber,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.pageNumber = $$v
                                                          },
                                                          expression:
                                                            "pageNumber",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            target:
                                                              "page-number",
                                                            placement: "top",
                                                            triggers: "focus",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\tType 'Enter' to go on page number\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-end align-items-center",
                                                  attrs: { cols: "3" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#00af8b",
                                                      },
                                                    },
                                                    [
                                                      _vm._v("Total rows: "),
                                                      _c("strong", [
                                                        _vm._v(
                                                          _vm._s(_vm.totalRows)
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    xl: "12",
                                                  },
                                                },
                                                [
                                                  _vm.$screen.width < 992
                                                    ? _c("CardListBuilder", {
                                                        attrs: {
                                                          items: _vm.Langres,
                                                          fields: _vm.fields,
                                                        },
                                                        on: {
                                                          "row-clicked":
                                                            _vm.rowClicked,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "actions",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _vm.isDev
                                                                    ? _c(
                                                                        "b-button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "none",
                                                                              size: "sm",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.delItem(
                                                                                  data.item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              _vm
                                                                                .ICONS
                                                                                .TRASH
                                                                                .name
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .TRASH
                                                                                      .color,
                                                                                  size: 20,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2154080107
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                  _vm.$screen.width >= 992
                                                    ? _c("b-table", {
                                                        ref: "myTable",
                                                        staticClass: "my-table",
                                                        staticStyle: {
                                                          "text-align": "left",
                                                          "background-color":
                                                            "white",
                                                        },
                                                        attrs: {
                                                          hover: _vm.hover,
                                                          responsive: "sm",
                                                          selectable: "",
                                                          selectedVariant:
                                                            _vm.selectedColor,
                                                          "select-mode":
                                                            _vm.selectMode,
                                                          items: _vm.Langres,
                                                          fields: _vm.fields,
                                                          "sticky-header":
                                                            "650px",
                                                          "head-variant":
                                                            _vm.hv,
                                                          bordered: "",
                                                          small: "",
                                                        },
                                                        on: {
                                                          "row-clicked":
                                                            _vm.rowClicked,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "cell(toCheck)",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-pointer p-2",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "lg",
                                                                              disabled:
                                                                                data
                                                                                  .item
                                                                                  .translatedMessage ===
                                                                                "AA",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.updateToCheckStatus(
                                                                                  data.item,
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .toCheck,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "toCheck",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.toCheck",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "cell(rem)",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _vm.isDev
                                                                    ? _c(
                                                                        "b-button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "none",
                                                                              size: "sm",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.delItem(
                                                                                  data.item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            _vm.getLucideIcon(
                                                                              _vm
                                                                                .ICONS
                                                                                .TRASH
                                                                                .name
                                                                            ),
                                                                            {
                                                                              tag: "component",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    _vm
                                                                                      .ICONS
                                                                                      .TRASH
                                                                                      .color,
                                                                                  size: 20,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          1178524022
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.showAIKeywords
                                    ? _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            xl: `${
                                              _vm.showAIKeywords ? 5 : 12
                                            }`,
                                          },
                                        },
                                        [
                                          _c(
                                            "fieldset",
                                            {
                                              staticClass:
                                                "scheduler-border fix-fieldset-card-inside-padding-bottom py-0 mb-0",
                                              staticStyle: {
                                                "background-color":
                                                  "rgb(248, 249, 251)",
                                              },
                                            },
                                            [
                                              _c(
                                                "legend",
                                                {
                                                  staticClass:
                                                    "card-inside text-color-rhapsody-in-blue-2",
                                                  staticStyle: {
                                                    "font-size": "1.25em",
                                                  },
                                                },
                                                [_vm._v("Key words")]
                                              ),
                                              _c(
                                                "b-row",
                                                [
                                                  _c("b-col", {
                                                    staticClass:
                                                      "d-flex justify-content-end",
                                                    attrs: { cols: "7" },
                                                  }),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-content-end align-items-center",
                                                      attrs: { cols: "5" },
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          ref: "button",
                                                          staticClass:
                                                            "btn-transparent text-color-rhapsody-in-blue",
                                                          attrs: {
                                                            disabled:
                                                              _vm.selectedItem !==
                                                              null
                                                                ? false
                                                                : _vm.isDisabledKeyWords,
                                                            id: "add-new-key-word-event",
                                                          },
                                                          on: {
                                                            mouseleave:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onShowTooltipAddKeyWord(
                                                                  false
                                                                )
                                                              },
                                                            mouseenter:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onShowTooltipAddKeyWord(
                                                                  true
                                                                )
                                                              },
                                                            click:
                                                              _vm.handleClosePopover,
                                                          },
                                                        },
                                                        [
                                                          _vm.selectedItem ===
                                                          null
                                                            ? _c(
                                                                _vm.getLucideIcon(
                                                                  "PlusCircle"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      "#00A09C",
                                                                    size: 24,
                                                                    "stroke-width": 2.25,
                                                                  },
                                                                }
                                                              )
                                                            : _c(
                                                                _vm.getLucideIcon(
                                                                  "Pencil"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      "#00A09C",
                                                                    size: 20,
                                                                    "stroke-width": 2,
                                                                  },
                                                                }
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-transparent text-color-rhapsody-in-blue",
                                                          attrs: {
                                                            disabled:
                                                              _vm.warningKeyword
                                                                .length === 0,
                                                            id: "save-event",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.saveKeywords,
                                                            mouseleave:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onShowTooltipSaveChages(
                                                                  false
                                                                )
                                                              },
                                                            mouseenter:
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onShowTooltipSaveChages(
                                                                  true
                                                                )
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "Save"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  "#225CBD",
                                                                size: 24,
                                                                "stroke-width": 2.25,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            show: _vm.showToolTipAddKeyWordChanges,
                                                            target:
                                                              "add-new-key-word-event",
                                                            placement: "right",
                                                          },
                                                          on: {
                                                            "update:show":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.showToolTipAddKeyWordChanges =
                                                                  $event
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _vm.selectedItem ===
                                                          null
                                                            ? _c("div", [
                                                                _vm._v(
                                                                  "Add new key word"
                                                                ),
                                                              ])
                                                            : _c("div", [
                                                                _vm._v(
                                                                  "Change key word"
                                                                ),
                                                              ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-tooltip",
                                                        {
                                                          attrs: {
                                                            show: _vm.showToolTipSaveChanges,
                                                            target:
                                                              "save-event",
                                                            placement: "top",
                                                          },
                                                          on: {
                                                            "update:show":
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.showToolTipSaveChanges =
                                                                  $event
                                                              },
                                                          },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "Save changes"
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c("FormKeyword", {
                                                        attrs: {
                                                          shortcutTriggered:
                                                            _vm.shortcutTriggered,
                                                          show: _vm.popoverShow,
                                                          target:
                                                            "add-new-key-word-event",
                                                          transtateItem:
                                                            _vm.selectedItem,
                                                        },
                                                        on: {
                                                          "on-add-key-word":
                                                            _vm.onAddKeyWord,
                                                          onClose: _vm.onClose,
                                                          variableSelected:
                                                            _vm.closekeywordPopup,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.warningKeyword
                                                ? _c(
                                                    "b-row",
                                                    { staticClass: "mt-3" },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _c(
                                                            "b-alert",
                                                            {
                                                              attrs: {
                                                                show: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.warningKeyword
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "b-row",
                                                {
                                                  class: _vm.warningKeyword
                                                    ? "mt-1"
                                                    : "mt-3",
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    [
                                                      _vm.$screen.width >= 992
                                                        ? _c("b-table", {
                                                            ref: "translation-key-word-table",
                                                            staticClass:
                                                              "my-table",
                                                            staticStyle: {
                                                              "text-align":
                                                                "left",
                                                              "background-color":
                                                                "white",
                                                            },
                                                            attrs: {
                                                              hover: _vm.hover,
                                                              responsive: "sm",
                                                              selectable: "",
                                                              selectedVariant:
                                                                _vm.selectedColor,
                                                              "select-mode":
                                                                _vm.selectMode,
                                                              items:
                                                                _vm.keywordList,
                                                              fields:
                                                                _vm.keywordFields,
                                                              "sticky-header":
                                                                "566px",
                                                              "head-variant":
                                                                _vm.hv,
                                                              bordered: "",
                                                              "show-empty": "",
                                                              "empty-text":
                                                                "No key word found.",
                                                              small: "",
                                                            },
                                                            on: {
                                                              "row-clicked":
                                                                _vm.keywordRowClicked,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "cell(reference)",
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _vm.setReference(
                                                                                    item
                                                                                  )
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: "cell(keyword)",
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  _vm.setKeyword(
                                                                                    item
                                                                                  )
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: "cell(rem)",
                                                                  fn: function (
                                                                    data
                                                                  ) {
                                                                    return [
                                                                      _vm.isDev
                                                                        ? _c(
                                                                            "b-button",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  variant:
                                                                                    "none",
                                                                                  size: "sm",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.removeKeyword(
                                                                                      data.item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                _vm.getLucideIcon(
                                                                                  _vm
                                                                                    .ICONS
                                                                                    .TRASH
                                                                                    .name
                                                                                ),
                                                                                {
                                                                                  tag: "component",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        _vm
                                                                                          .ICONS
                                                                                          .TRASH
                                                                                          .color,
                                                                                      size: 20,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              1245542300
                                                            ),
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.showModal
            ? _c(
                "div",
                [
                  _c("transition", { attrs: { name: "modal" } }, [
                    _c("div", { staticClass: "modal-mask" }, [
                      _c("div", { staticClass: "modal-wrapper" }, [
                        _c("div", { staticClass: "modal-dialog" }, [
                          _c("div", { staticClass: "modal-content" }, [
                            _c("div", { staticClass: "modal-header" }, [
                              _c("h4", { staticClass: "modal-title" }, [
                                _vm._v("HELP"),
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "close",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showModal = false
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { "aria-hidden": "true" } },
                                    [_vm._v("×")]
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "modal-body" }, [
                              _c("strong", [_vm._v("Value")]),
                              _vm._v(
                                " is the value of the menu item, if you want to create a new entry and do not care about this\n\t\t\t\t\t\t\t\t\tvalue: enter -1, it will be generated automatically "
                              ),
                              _c("br"),
                              _c("strong", [_vm._v("Factor")]),
                              _vm._v(
                                " is the attribute for CO2 factor in KGCO2/€ (from menu 900) for each text potentially appearing\n\t\t\t\t\t\t\t\t\tin an expense, "
                              ),
                              _c("br"),
                              _vm._v("\n\t\t\t\t\t\t\t\t\tor "),
                              _c("br"),
                              _c("strong", [_vm._v("Factor")]),
                              _vm._v(
                                " is the green category value (from menu 1016) for green tips categories related to menus number:\n\t\t\t\t\t\t\t\t\t"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1020 for tip of day user "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1021 for tip of day SME "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1022 for tip of day FILM "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1023 for tip of day AGENCY "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\t1024 for tip of day HOTEL\n\t\t\t\t\t\t\t\t\t"
                              ),
                              _c("br"),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"GREEN", "value": "0" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"REDUCE/REUSE/RECYCLE TIPS", "value": "1" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"KITCHEN GREEN TIPS", "value": "2" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"ECO-FRIENDLY BATHROOM TIPS", "value": "3" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"HOME ENERGY SAVING TIPS", "value": "4" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"WATER-SAVING TIPS", "value": "5" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"ORGANIC GARDENING TIPS", "value": "6" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"GAS SAVING TIPS", "value": "7" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"GREEN TRAVEL TIPS", "value": "8" '
                              ),
                              _c("br"),
                              _vm._v(
                                '\n\t\t\t\t\t\t\t\t\t"RESPONSIBLE ANIMAL ENCOUNTERS", "value": "9"\n\t\t\t\t\t\t\t\t'
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }