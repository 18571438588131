<style scoped>
.card-body >>> table > tbody > tr > td {
	cursor: pointer;
}

input[type='texte'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	background-color: white;
	color: white;
}

.my-table {
	max-height: 50vh;
	overflow-y: auto;
}

.my-label {
	color: red;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}
</style>

<style lang="scss">
// .custom-tooltip {
// 	.arrow::before {
// 		border-top-color: red !important;
// 	}
// 	.tooltip-inner {
// 		max-width: 250px !important;
// 		width: 250px !important;
// 		background-color: rgb(255, 255, 255) !important;
// 		color: black;
// 		border: 1px solid rgb(187, 187, 187);
// 	}
// }
.tooltip-inner:has(.priority-custom-tooltip) {
	max-width: 250px !important;
	width: 250px !important;
	background-color: rgb(255, 255, 255) !important;
	color: black;
	border: 1px solid rgb(187, 187, 187);
}
.custom-tooltip:has(.arrow::before) {
	bottom: 0 !important;
	border-width: 0 0.4rem 0.4rem !important;
	border-bottom-color: #8b9121 !important;
	background-color: #8b9121 !important;
}

.gap-4 {
	gap: 4px;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<b-row class="container-layout px-0">
			<b-col md="12">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
					{{ this.caption1 }}
				</h1>
			</b-col>
			<b-col cols="12" xl="12">
				<b-overlay :show.sync="isBusyAllScreen" rounded="lg">
					<div class="animated fadeIn">
						<b-card no-body class="card-border-blue-light">
							<b-card-body class="form content-card-collapsible">
								<fieldset class="scheduler-border fix-fieldset-card-inside-padding-bottom" style="background-color: rgb(248, 249, 251)">
									<legend class="card-inside text-color-rhapsody-in-blue-2" style="font-size: 1.25em">Advanced search</legend>
									<b-row>
										<b-col cols="6">
											<b-form-group label="Choice" label-for="choice" :label-cols="4">
												<b-form-select
													v-model="currentChoice"
													:options="choiceOptions"
													@change="choiceChanged"
													size="md"
												></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group label="From English to" label-for="language" :label-cols="4">
												<b-form-select
													v-model="currentLanguage"
													:options="optionsForLanguage"
													@change="languageChanged"
													size="md"
												></b-form-select>
											</b-form-group>
										</b-col>
									</b-row>
									<b-row v-if="currentChoice == 0">
										<b-col cols="6">
											<b-form-group label="Application" label-for="appChoice" :label-cols="4">
												<b-form-select
													v-model="currentApp"
													:options="Apps"
													id="appChoice"
													@change="appChanged"
													size="md"
												></b-form-select>
											</b-form-group>
										</b-col>
										<b-col cols="6">
											<b-form-group label="Form" label-for="formChoice" :label-cols="4">
												<v-select
													v-model="currentForm"
													label="formName"
													:options="Forms"
													:reduce="(option) => option.formNumber"
													:clearable="false"
													@option:selected="formChanged"
												/>
											</b-form-group>
										</b-col>
									</b-row>
								</fieldset>
								<fieldset class="fix-fieldset-card-inside-padding-bottom my-3 py-0" style="background-color: #ffffff">
									<b-alert :show="addedMessageAlert" dismissible fade variant="primary" @dismissed="closeAlert">
										<div class="d-flex flex-row justify-content-center align-items-center pb-2">
											<strong>-- Last New Message --</strong>
										</div>
										<div class="d-flex flex-row justify-content-start align-items-center pb-2">
											<b-col cols="4" class="pl-0">
												<div class="d-flex flex-row">
													<div class="pr-4">
														<div class="text-center">
															<strong>Number</strong>
														</div>
														<div class="text-center">{{ newMessageInfo.number }}</div>
													</div>
													<div class="pr-4" v-if="currentChoice !== 2">
														<div class="text-center">
															<strong>Value</strong>
														</div>
														<div class="text-center">{{ newMessageInfo.value }}</div>
													</div>
													<div v-if="currentChoice === 1">
														<div class="text-center">
															<strong>Factor</strong>
														</div>
														<div class="text-center">{{ newMessageInfo.factor }}</div>
													</div>
												</div>
											</b-col>
										</div>
										<div class="d-flex flex-row justify-content-start align-items-center">
											<div>
												<div class="text-left">
													<strong>Message</strong>
												</div>
												<div>&laquo; {{ newMessageInfo.message }} &raquo;</div>
											</div>
										</div>
									</b-alert>
									<fieldset class="scheduler-border fix-fieldset-card-inside-padding-bottom py-0 mb-0">
										<legend class="card-inside text-color-rhapsody-in-blue-2" style="font-size: 1.25em">Translations</legend>
										<b-row>
											<b-col cols="9" class="pb-4">
												<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0">
													<legend class="card-inside">&laquo; {{ newLangres.message }} &raquo;</legend>
													<b-row>
														<b-col cols="12">
															<b-form-group :label="translatedLabel" label-for="msgTranslated" :label-cols="2">
																<b-form-textarea
																	v-model="newLangres.translatedMessage"
																	type="textarea"
																	rows="2"
																	autocomplete="off"
																	id="msgTranslated"
																	:class="{ 'is-invalid': isSubmitted && $v.newLangres.translatedMessage.$error }"
																/>
																<div v-if="isSubmitted && !$v.newLangres.translatedMessage.required" class="invalid-feedback">
																	Message is required
																</div>
															</b-form-group>
														</b-col>
													</b-row>
													<b-row>
														<b-col cols="2"></b-col>
														<b-col>
															<b-row class="d-flex justify-content-end align-items-center">
																<b-col :cols="numberCols">
																	<b-form-group label="Number" label-for="resNumber">
																		<b-form-input v-model="newLangres.number" type="number" id="resNumber"></b-form-input>
																	</b-form-group>
																</b-col>
																<b-col :cols="valueCols" v-if="currentChoice !== 2">
																	<b-form-group label="Value" label-for="resValue">
																		<b-form-input v-model="newLangres.value" type="number" id="resValue"></b-form-input>
																	</b-form-group>
																</b-col>
																<b-col :cols="factorCols" v-if="currentChoice === 1">
																	<b-form-group label="Factor" label-for="factor">
																		<b-form-input v-model="newLangres.paramFloat" type="number" id="factor"></b-form-input>
																	</b-form-group>
																</b-col>
																<b-col :cols="priorityCols" v-if="currentChoice === 1 && showAIKeywords">
																	<b-form-group>
																		<template slot="label">
																			<div class="d-flex flex-row justify-content-start align-items-center gap-4">
																				<label for="priority">Priority</label>
																				<div id="priority-tooltip" class="text-color-blue-streak">
																					<component :is="getLucideIcon('Info')" :size="16" />
																				</div>
																				<b-tooltip target="priority-tooltip" custom-class="custom-tooltip">
																					<div class="priority-custom-tooltip p-1">
																						Keywords are processed from lowest to highest priority value (0 first,
																						100 last)
																					</div>
																				</b-tooltip>
																			</div>
																		</template>
																		<b-form-input
																			v-model="newLangres.priority"
																			type="number"
																			id="priority"
																			:max="1000"
																		></b-form-input>
																	</b-form-group>
																</b-col>
																<b-col :cols="helpCols" v-if="currentChoice === 1">
																	<button
																		class="btn-transparent text-color-rhapsody-in-blue"
																		id="show-modal"
																		@click="showModal = true"
																	>
																		<component :is="getLucideIcon('Info')" color="#225CBD" :size="22" />
																	</button>
																</b-col>
															</b-row>
														</b-col>
														<div class="w-100 d-flex flex-row justify-content-end pb-3">
															<b-col cols="3">
																<b-button @click="newMessage" variant="outline-secondary" block>New</b-button>
															</b-col>
															<b-col cols="3">
																<b-button @click="curLangresUpd" variant="primary" :disabled="disableSave" block>Save</b-button>
															</b-col>
														</div>
													</b-row>
												</fieldset>
											</b-col>
											<b-col cols="3">
												<fieldset class="card-inside fix-fieldset-card-inside-padding-bottom my-0 py-0">
													<legend class="card-inside">Google</legend>
													<b-row class="pb-4">
														<b-col cols="12">
															<b-form-checkbox
																size="lg"
																v-model="valueGoogleSelected"
																@change="selectGoogleTranslation"
																class="pj-cb pb-2"
																value="all"
															>
																All items in all Languages
															</b-form-checkbox>
															<b-form-checkbox
																size="lg"
																v-model="valueGoogleSelected"
																@change="selectGoogleTranslation"
																class="pj-cb pb-3"
																:value="currentLanguage"
																>All items in {{ languageChosen }}</b-form-checkbox
															>
														</b-col>
													</b-row>
													<div class="w-100 d-flex flex-row justify-content pb-3" style="padding-top: 41px">
														<b-col cols="12">
															<b-button
																variant="primary"
																:disabled="disableGoogleTranslation"
																@click="doTranslation(valueGoogleSelected)"
																block
															>
																Translate
															</b-button>
														</b-col>
													</div>
												</fieldset>
											</b-col>
										</b-row>
									</fieldset>
								</fieldset>
								<fieldset
									class="scheduler-border fix-fieldset-card-inside-padding-bottom pt-0 pb-3"
									style="background-color: rgb(248, 249, 251)"
								>
									<legend class="card-inside text-color-rhapsody-in-blue-2" style="font-size: 1.25em">Filters</legend>
									<b-row>
										<b-col cols="8" class="mt-1">
											<b-form-group
												label="Per page"
												label-for="per-page-select"
												label-cols-sm="2"
												label-cols-md="2"
												label-cols-lg="2"
												label-size="sm"
												class="mb-3"
											>
												<b-form-select
													id="per-page-select"
													v-model="perPage"
													:options="pageOptions"
													@change="changeNumberRows"
													size="sm"
												></b-form-select>
											</b-form-group>
											<b-form-group
												label="Menu number"
												label-cols-sm="2"
												label-cols-md="2"
												label-cols-lg="2"
												label-align-sm="left"
												label-size="sm"
												label-for="number-res"
												class="mb-3"
												v-if="currentChoice === 1"
											>
												<b-input-group>
													<b-form-input
														v-model="numberRes"
														:disabled="disableMenuNumber"
														id="number-res"
														type="number"
														step="1"
														min="0"
														placeholder="0"
														@keyup="handleChangeResInit"
														@keyup.enter="handleChangeRes"
													/>
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search" :disabled="disableMenuNumber">
															<component
																:is="getIconLucide('Search')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																v-if="numberRes === 0"
															/>
															<component
																:is="getIconLucide('X')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																@click="clearResNumber"
																v-else
															/>
														</b-input-group-text>
														<b-tooltip target="number-res" placement="top" triggers="focus">
															Type 'Enter' to launch search
														</b-tooltip>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
											<b-form-group
												label="Filter"
												label-cols-sm="2"
												label-cols-md="2"
												label-cols-lg="2"
												label-align-sm="left"
												label-size="sm"
												label-for="filterInput"
												class="mb-3"
											>
												<b-input-group>
													<b-form-input
														v-model.trim="filter"
														@keydown.enter="search($event)"
														@keyup.delete="removeFilter"
														type="text"
														id="filterInput"
														placeholder="Type to search..."
														autocomplete="off"
														v-b-tooltip.focus
													/>
													<b-input-group-append class="cursor-pointer">
														<b-input-group-text class="btn-search">
															<component
																:is="getIconLucide('Search')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																v-if="filter.length === 0"
															/>
															<component
																:is="getIconLucide('X')"
																color="#FFFFFF"
																:size="16"
																class="icon"
																:stroke-width="2.5"
																@click="clearFilter"
																v-else
															/>
														</b-input-group-text>
														<b-tooltip target="filterInput" placement="top" triggers="focus">
															Type 'Enter' to launch search
														</b-tooltip>
													</b-input-group-append>
												</b-input-group>
											</b-form-group>
											<b-form-group
												v-if="showAIKeywords"
												label="Filter Category"
												label-cols-sm="2"
												label-cols-md="2"
												label-cols-lg="2"
												label-align-sm="left"
												label-size="sm"
												label-for="filterInput"
												class="mb-2"
											>
												<v-select
													v-model="selectedCategory"
													placeholder="Select a carbon category"
													:options="carbonListWithReference"
													@search="filterCategories"
													label="message"
													@input="handleSelectedOption"
												>
												</v-select>
											</b-form-group>
										</b-col>
										<b-col cols="4" class="d-flex flex-column justify-content-center align-items-end">
											<b-form-checkbox
												size="lg"
												class="pj-cb mt-1"
												v-model="showAllMessagesToVerify"
												@change="getShowAllMessagesToVerify"
											>
												<div style="margin-top: 2px">Show all messages to verify</div>
											</b-form-checkbox>
											<b-form-checkbox
												size="lg"
												class="pj-cb mt-1"
												v-model="showAIKeywords"
												@change="getShowAIkeywords"
												v-if="langresType === LANG_RES_TYPE.menu && isDev"
											>
												<div style="margin-top: 2px">Display AI key words</div>
											</b-form-checkbox>
										</b-col>
									</b-row>
								</fieldset>
								<b-row>
									<b-col :xl="`${showAIKeywords ? 7 : 12}`">
										<fieldset
											class="scheduler-border fix-fieldset-card-inside-padding-bottom py-0 mb-0"
											style="background-color: rgb(248, 249, 251)"
										>
											<legend class="card-inside text-color-rhapsody-in-blue-2" style="font-size: 1.25em">Translation records</legend>
											<b-row>
												<b-col cols="6" xl="6">
													<b-pagination
														v-model="currentPage"
														:total-rows="totalRows"
														:per-page="perPage"
														align="fill"
														size="md"
														class="mt-1 mb-2"
														@change="onPaginated"
													></b-pagination>
												</b-col>
												<b-col cols="3" xl="3">
													<b-form-group label="Page n°" label-for="page-number" :label-cols="4">
														<b-form-input
															v-model="pageNumber"
															type="number"
															id="page-number"
															min="0"
															step="1"
															@keydown.enter="goToPage($event)"
														></b-form-input>
														<b-tooltip target="page-number" placement="top" triggers="focus">
															Type 'Enter' to go on page number
														</b-tooltip>
													</b-form-group>
												</b-col>
												<b-col cols="3" class="d-flex justify-content-end align-items-center">
													<span style="color: #00af8b"
														>Total rows: <strong>{{ totalRows }}</strong></span
													>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="12" xl="12">
													<CardListBuilder v-if="$screen.width < 992" :items="Langres" :fields="fields" @row-clicked="rowClicked">
														<template slot="actions" slot-scope="data">
															<b-button v-if="isDev" variant="none" size="sm" @click="delItem(data.item)">
																<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
															</b-button>
														</template>
													</CardListBuilder>
													<b-table
														v-if="$screen.width >= 992"
														:hover="hover"
														class="my-table"
														responsive="sm"
														ref="myTable"
														selectable
														:selectedVariant="selectedColor"
														:select-mode="selectMode"
														:items="Langres"
														style="text-align: left; background-color: white"
														:fields="fields"
														sticky-header="650px"
														@row-clicked="rowClicked"
														:head-variant="hv"
														bordered
														small
													>
														<template v-slot:cell(toCheck)="data">
															<div class="cursor-pointer p-2">
																<b-form-checkbox
																	v-model="data.item.toCheck"
																	size="lg"
																	@change="updateToCheckStatus(data.item, $event)"
																	:disabled="data.item.translatedMessage === 'AA'"
																/>
															</div>
														</template>
														<template v-slot:cell(rem)="data">
															<b-button v-if="isDev" variant="none" size="sm" @click="delItem(data.item)">
																<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
															</b-button>
														</template>
													</b-table>
												</b-col>
											</b-row>
										</fieldset>
									</b-col>
									<b-col :xl="`${showAIKeywords ? 5 : 12}`" v-if="showAIKeywords">
										<fieldset
											class="scheduler-border fix-fieldset-card-inside-padding-bottom py-0 mb-0"
											style="background-color: rgb(248, 249, 251)"
										>
											<legend class="card-inside text-color-rhapsody-in-blue-2" style="font-size: 1.25em">Key words</legend>
											<b-row>
												<b-col cols="7" class="d-flex justify-content-end"> </b-col>
												<b-col cols="5" class="d-flex justify-content-end align-items-center">
													<button
														:disabled="selectedItem !== null ? false : isDisabledKeyWords"
														ref="button"
														id="add-new-key-word-event"
														@mouseleave="onShowTooltipAddKeyWord(false)"
														@mouseenter="onShowTooltipAddKeyWord(true)"
														class="btn-transparent text-color-rhapsody-in-blue"
														@click="handleClosePopover"
													>
														<component
															v-if="selectedItem === null"
															:is="getLucideIcon('PlusCircle')"
															color="#00A09C"
															:size="24"
															:stroke-width="2.25"
														/>
														<component v-else :is="getLucideIcon('Pencil')" color="#00A09C" :size="20" :stroke-width="2" />
													</button>
													<button
														class="btn-transparent text-color-rhapsody-in-blue"
														@click="saveKeywords"
														:disabled="warningKeyword.length === 0"
														@mouseleave="onShowTooltipSaveChages(false)"
														@mouseenter="onShowTooltipSaveChages(true)"
														id="save-event"
													>
														<component :is="getLucideIcon('Save')" color="#225CBD" :size="24" :stroke-width="2.25" />
													</button>
													<b-tooltip :show.sync="showToolTipAddKeyWordChanges" target="add-new-key-word-event" placement="right">
														<div v-if="selectedItem === null">Add new key word</div>
														<div v-else>Change key word</div>
													</b-tooltip>
													<b-tooltip :show.sync="showToolTipSaveChanges" target="save-event" placement="top">
														<div>Save changes</div>
													</b-tooltip>
													<FormKeyword
														:shortcutTriggered="shortcutTriggered"
														:show="popoverShow"
														@on-add-key-word="onAddKeyWord"
														@onClose="onClose"
														@variableSelected="closekeywordPopup"
														target="add-new-key-word-event"
														:transtateItem="selectedItem"
													/>
												</b-col>
											</b-row>
											<b-row class="mt-3" v-if="warningKeyword">
												<b-col
													><b-alert show>{{ warningKeyword }}</b-alert>
												</b-col>
											</b-row>
											<b-row :class="warningKeyword ? 'mt-1' : 'mt-3'">
												<b-col>
													<b-table
														v-if="$screen.width >= 992"
														:hover="hover"
														ref="translation-key-word-table"
														class="my-table"
														responsive="sm"
														selectable
														:selectedVariant="selectedColor"
														:select-mode="selectMode"
														:items="keywordList"
														style="text-align: left; background-color: white"
														:fields="keywordFields"
														sticky-header="566px"
														@row-clicked="keywordRowClicked"
														:head-variant="hv"
														bordered
														show-empty
														:empty-text="'No key word found.'"
														small
													>
														<template #cell(reference)="{ item }">
															<div v-html="setReference(item)"></div>
														</template>
														<template #cell(keyword)="{ item }">
															<div v-html="setKeyword(item)"></div>
														</template>
														<template v-slot:cell(rem)="data">
															<b-button v-if="isDev" variant="none" size="sm" @click="removeKeyword(data.item)">
																<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
															</b-button>
														</template>
													</b-table>
												</b-col>
											</b-row>
										</fieldset>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</div>
				</b-overlay>
			</b-col>
			<div v-if="showModal">
				<transition name="modal">
					<div class="modal-mask">
						<div class="modal-wrapper">
							<div class="modal-dialog">
								<div class="modal-content">
									<div class="modal-header">
										<h4 class="modal-title">HELP</h4>
										<button type="button" class="close" @click="showModal = false">
											<span aria-hidden="true">&times;</span>
										</button>
									</div>
									<div class="modal-body">
										<strong>Value</strong> is the value of the menu item, if you want to create a new entry and do not care about this
										value: enter -1, it will be generated automatically <br />
										<strong>Factor</strong> is the attribute for CO2 factor in KGCO2/€ (from menu 900) for each text potentially appearing
										in an expense, <br />
										or <br />
										<strong>Factor</strong> is the green category value (from menu 1016) for green tips categories related to menus number:
										<br />
										1020 for tip of day user <br />
										1021 for tip of day SME <br />
										1022 for tip of day FILM <br />
										1023 for tip of day AGENCY <br />
										1024 for tip of day HOTEL
										<br />

										<br />
										"GREEN", "value": "0" <br />
										"REDUCE/REUSE/RECYCLE TIPS", "value": "1" <br />
										"KITCHEN GREEN TIPS", "value": "2" <br />
										"ECO-FRIENDLY BATHROOM TIPS", "value": "3" <br />
										"HOME ENERGY SAVING TIPS", "value": "4" <br />
										"WATER-SAVING TIPS", "value": "5" <br />
										"ORGANIC GARDENING TIPS", "value": "6" <br />
										"GAS SAVING TIPS", "value": "7" <br />
										"GREEN TRAVEL TIPS", "value": "8" <br />
										"RESPONSIBLE ANIMAL ENCOUNTERS", "value": "9"
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</div>
		</b-row>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { isNil, generateSecureId } from '~utils';
import * as icons from 'lucide-vue';
import {
	getAllFormsContentForTranslation,
	getAllMessagesForTranslation,
	getAllMenusContentForTranslation,
	addUpdLangResItem,
	deleteFormItem,
	deleteLanguageItem,
	translateAllItems,
	getAllFormsNameOnly,
	searchtAllFormsContentForTranslation,
	searchAllMenusContentForTranslation,
	searchAllMessagesForTranslation,
	updateLanguageCheckStatus,
	translateLanguageItems,
	getAIKeywords
} from '@/cruds/language.crud';
import globalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { LANG_RES_TYPE } from '@/shared/constants';
import FormKeyword from '@/components/Translation/FormKeyword';
import { getCarbonCategoryWithKeywords } from '@/cruds/carbon.crud';
import * as _ from 'lodash';

export default {
	name: 'Translations',
	mixins: [languageMessages, globalMixin, validationMixin],
	components: {
		FormKeyword
	},
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	},
	data: () => {
		return {
			_useParamsItems: null,
			selectedItem: null,
			params: null,
			hv: 'dark',
			showModal: false,
			erreur: {},
			currentForm: {
				formNumber: 0,
				formName: 'All Forms'
			},
			disableMenuNumber: false,
			webForm: true, // webapp=true mobileapp=false
			currentLanguage: 0,
			currentApp: 0, // webapp=0 mobileapp=1
			currentChoice: 0,
			choiceOptions: [
				{
					value: 0,
					text: 'Forms'
				},
				{
					value: 1,
					text: 'Menus'
				},
				{
					value: 2,
					text: 'Messages'
				}
			],
			Langres: [], // selected for display
			Apps: [
				{
					value: 0,
					text: 'Web'
				},
				{
					value: 1,
					text: 'Mobile'
				}
			],
			Forms: [],
			allForms: {
				formNumber: 0,
				formName: 'All Forms'
			},
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 20, 50, 100, 150, 200],
			warning: '',
			warningKeyword: '',
			filter: '',
			newCreated: 0,
			stopPropagation: '',
			selectMode: 'single',
			selectedColor: 'primary',
			stickyHeader: true,
			successModal: false,
			successModalDelete: false,
			curLangres: {},
			newLangres: {},
			numberCols: 3,
			valueCols: 3,
			factorCols: 2,
			priorityCols: 2,
			helpCols: 2,
			isBusyAllScreen: false,
			languageChosen: '',
			valueGoogleSelected: [],
			currentTableIndex: 0,
			searchSubmitted: false,
			pageNumber: 0,
			stateMessageAction: 1, // add=0, update=1
			langresType: 4, // FORM_WEB=4, FORM_MOBILE=3, MENU=1, MESSAGE=0
			showAllMessagesToVerify: false,
			isSubmitted: false,
			newMessageInfo: {
				message: '',
				number: 0,
				value: 0,
				factor: 0,
				priority: 0
			},
			addedMessageAlert: false,
			copyLangres: {},
			LANG_RES_TYPE,
			showAIKeywords: false,
			selectedCategory: null,
			carbonListWithReference: [],
			filteredCategories: [],
			references: [],
			keywords: [],
			keywordsTemp: [],
			keywordsFilter: [],
			keywordList: [],
			rowSelected: {},
			////
			input1: '',
			options: [{ text: '- Choose 1 -', value: '' }, 'Red', 'Green', 'Blue'],
			popoverShow: false,
			shortcutTriggered: false,
			textUniqueID: 'new-key-1000000-',
			numberRes: 0,
			showToolTipAddKeyWordChanges: false,
			showToolTipSaveChanges: false
		};
	},
	computed: {
		isDev() {
			return store.isDev();
		},
		isInEnglish() {
			return this.currentLanguage === 0 ? true : false;
		},
		isDisabledKeyWords() {
			if (this.keywordList.length < 3) {
				return false;
			}
			return true;
		},
		fields() {
			let array = [
				{
					key: 'formName',
					label: this.firstColTitle(),
					sortable: true
				},
				{
					key: 'number',
					label: this.secondColTitle(),
					sortable: true,
					class: 'text-center'
				}
			];

			if (this.currentChoice !== 2) {
				array.push({
					key: 'value',
					label: 'Value',
					sortable: true,
					class: 'text-center'
				});
			}

			if (this.currentChoice === 1) {
				array.push({
					key: 'paramFloat',
					label: 'Factor',
					sortable: true,
					class: 'text-center'
				});
				if (this.showAIKeywords) {
					array.push({
						key: 'priority',
						label: 'Priority',
						sortable: true,
						class: 'text-center'
					});
				}
			}

			array.push({
				key: 'message',
				label: 'Message',
				sortable: true
			});

			array.push({
				key: 'translatedMessage',
				label: 'Translation',
				sortable: true
			});

			array.push({
				key: 'toCheck',
				label: 'To verify',
				sortable: false,
				formatter: (v) => v,
				class: 'text-center'
			});

			array.push({
				key: 'rem',
				label: 'Remove',
				sortable: false,
				formatter: (v) => v,
				class: 'text-center'
			});

			return array;
		},
		optionsForLanguage() {
			if (this.isDev) {
				return this.FormMenu(1);
			} else {
				this.currentLanguage = 1;
				let Menu = this.FormMenu(1);
				return Menu.filter((data) => data.value != 0);
			}
		},
		translatedLabel() {
			let str = 'Translate into ';
			for (let i = 0; i < this.optionsForLanguage.length; i++) {
				if (this.optionsForLanguage[i].value == this.currentLanguage) {
					str += this.optionsForLanguage[i].text;
					this.languageChosen = this.optionsForLanguage[i].text;
					break;
				}
			}

			return str;
		},
		caption1() {
			//return this.FormMSG(8,"Translations for form:");
			return 'Translations';
		},
		disableSave() {
			if (
				this.curLangres.translatedMessage != this.newLangres.translatedMessage ||
				this.curLangres.number != this.newLangres.number ||
				this.curLangres.value != this.newLangres.value ||
				this.curLangres.paramFloat != this.newLangres.paramFloat ||
				this.curLangres.priority != this.newLangres.priority
			) {
				if ((this.curLangres.type == 1 && this.curLangres.number == 3) || (this.curLangres.type == 1 && this.curLangres.number == 6)) {
					// do not translate list of forms and web forms
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		},
		disableGoogleTranslation() {
			return this.valueGoogleSelected.length === 0;
		},
		keywordFields() {
			let fields = [];

			if (!this.isInEnglish) {
				fields.push({
					key: 'reference',
					label: 'Reference',
					sortable: true,
					class: 'text-left'
				});
			}

			fields.push({
				key: 'keyword',
				label: 'Key word',
				sortable: true,
				class: 'text-left'
			});

			fields.push({
				key: 'rem',
				label: '*',
				sortable: false,
				formatter: (v) => v,
				class: 'text-center',
				thStyle: { width: '2%' }
			});

			return fields;
		}
	},
	methods: {
		handleClosePopover() {
			this.popoverShow = !this.popoverShow;
		},
		onShowTooltipAddKeyWord(value) {
			if (value) {
				this.showToolTipAddKeyWordChanges = true;
			} else {
				this.showToolTipAddKeyWordChanges = false;
			}
		},
		onShowTooltipSaveChages(value) {
			if (value) {
				this.showToolTipSaveChanges = true;
			} else {
				this.showToolTipSaveChanges = false;
			}
		},
		async handleChangeResInit(event) {
			const value = +event.target.value;
			this.numberRes = value;
			if (value === 0) {
				const option = {
					message: null,
					number: value,
					value: null
				};
				this.handleSelectedOption(option);
				this.setTotalRows(await this.getTotalRowsLangresOfSearch());
			}
		},
		async handleChangeRes(event) {
			const value = +event.target.value;
			this.numberRes = value;
			if (value !== 0) {
				const option = {
					message: null,
					number: value,
					value: null
				};
				this.handleSelectedOption(option);
				this.setTotalRows(await this.getTotalRowsLangresOfSearch());
			}
		},
		clearResNumber() {
			this.numberRes = 0;
			const option = {
				message: null,
				number: null,
				value: null
			};
			this.handleSelectedOption(option);
		},
		setReference(item) {
			if (!_.isNil(item)) {
				const text = item.reference;
				const isReferenceValid = text.includes(generateSecureId(this.textUniqueID));
				if (!isReferenceValid) {
					const _text = text.toString().split('\n');
					const arraysText = [];
					for (let i = 0; i < _text.length; i++) {
						arraysText.push(`<div>${_text[i].replaceAll('<', '&lt;').replaceAll('>', '&gt;')}</div>`);
					}
					return arraysText.join('');
				}
				return '';
			}
			return '';
		},
		setKeyword(item) {
			if (!_.isNil(item)) {
				const keyword = item.keyword;
				if (keyword.length === 0) {
					return '';
				} else {
					const text = keyword.toString().split('\n');
					const arraysText = [];
					for (let i = 0; i < text.length; i++) {
						arraysText.push(`<div>${text[i].replaceAll('<', '&lt;').replaceAll('>', '&gt;')}</div>`);
					}
					return arraysText.join('');
				}
			}
			return '';
		},
		closekeywordPopup() {
			this.shortcutTriggered = false;
		},
		_useParams(number, value) {
			this._useParamsItems = {
				...this._useParamsItems,
				filter: this.filter,
				category: this.selectedCategory,
				currentForm: this.currentForm,
				numberRes: +this.numberRes,
				formatMessageForFilterCategory: {
					number: +number || 0,
					value: +value || null
				}
			};
			return {
				...this._useParamsItems
			};
		},
		async handleSelectedOption(option) {
			let results = await searchAllMenusContentForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this._useParams(option ? option.number : null, option ? option.value : null),
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);
			const totalRows = results.length || 0;
			this.setTotalRows(totalRows);
			this.Langres = results;
			this.$nextTick(async () => {
				await this.setDefaultRow();
			});
		},
		mapKeywordList() {
			this.keywordList = this.keywords.map((item, i) => ({
				keyword: item.name,
				reference: ''
			}));
			this.references.forEach((item, i) => {
				if (this.keywordList[i]) {
					this.keywordList[i].reference = item.name;
				} else {
					this.keywordList.push({ keyword: '', reference: item.name });
				}
			});
			this.keywordList = this.keywordList.map((item, i) => ({ ...item, index: i + 1 })).sort((a, b) => b.index - a.index);
		},
		filterCategories(searchText) {
			// Filter categories based on input text
			this.filteredCategories = this.carbonListWithReference.filter((category) => category.message.toLowerCase().includes(searchText.toLowerCase()));
		},
		resetfilterKeyword() {
			this.filterKeyword = '';
		},
		choiceChanged() {
			// this.filter = '';
			this.pageNumber = 0;
			this.searchSubmitted = false;
			this.selectedItem = null;
			this.handleAction();
		},
		handleAction(pageNumber = 1) {
			this.showAIKeywords = false;

			if (this.currentChoice === 1) {
				this.langresType = LANG_RES_TYPE.menu; // 1
			} else if (this.currentChoice === 2) {
				this.langresType = LANG_RES_TYPE.msg; // 0
			} else {
				if (this.currentApp === 0) {
					this.langresType = LANG_RES_TYPE.formWeb; // 4
				} else {
					this.langresType = LANG_RES_TYPE.form; // 3
				}
			}

			this.isBusyAllScreen = true;

			this.$nextTick(async () => {
				this.currentPage = pageNumber;
				this.calcManualTranslationCols(this.currentChoice);

				if (this.currentChoice === 1) {
					await this.loadMenus()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangres());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				} else if (this.currentChoice === 2) {
					await this.loadMessages()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangres());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				} else {
					await this.loadLangresForms()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangres());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				}
			});
		},
		searchAction(copyFilter = '') {
			this.isBusyAllScreen = true;
			this.filter = copyFilter;
			this.$nextTick(async () => {
				this.currentPage = 1;
				// this.calcManualTranslationCols(e);

				if (this.currentChoice === 1) {
					await this.searchMenus()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangresOfSearch());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				} else if (this.currentChoice === 2) {
					await this.searchMessages()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangresOfSearch());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				} else {
					await this.searchForms()
						.then(async () => {
							this.setTotalRows(await this.getTotalRowsLangresOfSearch());
						})
						.catch((e) => {
							console.error({ e });
						})
						.finally(() => {
							this.setDefaultRow();
							this.isBusyAllScreen = false;
						});
				}
			});
		},
		firstColTitle() {
			if (this.currentChoice === 1) {
				return 'Menu name';
			} else if (this.currentChoice === 2) {
				return 'Message';
			} else {
				return 'Form name';
			}
		},
		secondColTitle() {
			if (this.currentChoice === 1) {
				return 'Menu number';
			} else if (this.currentChoice === 2) {
				return 'Message number';
			} else {
				return 'Form number';
			}
		},
		async appChanged(e) {
			if (e === 0) {
				this.webForm = true;
				this.langresType = LANG_RES_TYPE.formWeb;
			} else if (e === 1) {
				this.webForm = false;
				this.langresType = LANG_RES_TYPE.form;
			}

			this.currentForm = {
				...this.currentForm,
				formNumber: 0
			}; // reset to all forms
			this.currentPage = 1;
			this.filter = '';
			this.pageNumber = 0;
			this.searchSubmitted = false;
			this.isBusyAllScreen = true;

			await this.loadLangresForms()
				.then(async () => {
					this.setTotalRows(await this.getTotalRowsLangres());
				})
				.catch((e) => {
					console.error({ e });
				})
				.finally(() => {
					this.setDefaultRow();
					this.isBusyAllScreen = false;
				});
		},
		async formChanged(e) {
			this.currentPage = 1;
			this.filter = '';
			this.pageNumber = 0;
			this.searchSubmitted = false;
			this.isBusyAllScreen = true;
			const formName = e.formName;
			const formNumber = e.formNumber || 0;
			this.currentForm = { ...this.currentForm, formName, formNumber };

			await this.loadLangresForms()
				.then(async () => {
					this.setTotalRows(await this.getTotalRowsLangres());
				})
				.catch((e) => {
					console.error({ e });
				})
				.finally(() => {
					this.setDefaultRow();
					this.isBusyAllScreen = false;
				});
		},
		languageChanged() {
			this.choiceChanged();
			this.selectedItem = null;
		},
		async getTotalRowsLangres() {
			let records = [];
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}

			switch (this.currentChoice) {
				case 0:
					records = await getAllFormsContentForTranslation(this.currentLanguage, this.webForm, this.params, this.showAllMessagesToVerify);

					break;
				case 1:
					records = await searchAllMenusContentForTranslation(this.currentLanguage, this.showAllMessagesToVerify, this.params, null, null);

					break;
				case 2:
					records = await getAllMessagesForTranslation(this.currentLanguage, this.showAllMessagesToVerify);
					break;
			}

			return records.length;
		},
		async getTotalRowsLangresOfSearch() {
			let records = [];
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}

			switch (this.currentChoice) {
				case 0:
					records = await searchtAllFormsContentForTranslation(
						this.currentLanguage,
						this.webForm,
						this.currentForm,
						this.showAllMessagesToVerify,
						this.params
					);

					break;
				case 1:
					records = await searchAllMenusContentForTranslation(this.currentLanguage, this.showAllMessagesToVerify, this.params, null, null);

					break;
				case 2:
					records = await searchAllMessagesForTranslation(this.currentLanguage, this.showAllMessagesToVerify, this.params);
					break;
			}

			return records.length;
		},
		async handlePaginateAction() {
			let records = [];
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}
			switch (this.currentChoice) {
				case 0:
					records = await getAllFormsContentForTranslation(
						this.currentLanguage,
						this.webForm,
						this.params,
						this.showAllMessagesToVerify,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
				case 1:
					records = await getAllMenusContentForTranslation(
						this.currentLanguage,
						this.showAllMessagesToVerify,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
				case 2:
					records = await getAllMessagesForTranslation(
						this.currentLanguage,
						this.showAllMessagesToVerify,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
			}

			return records;
		},
		async searchPaginateAction() {
			let records = [];
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}
			switch (this.currentChoice) {
				case 0:
					records = await searchtAllFormsContentForTranslation(
						this.currentLanguage,
						this.webForm,
						this.currentForm,
						this.showAllMessagesToVerify,
						this.params,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
				case 1:
					records = await searchAllMenusContentForTranslation(
						this.currentLanguage,
						this.showAllMessagesToVerify,
						this.params,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
				case 2:
					records = await searchAllMessagesForTranslation(
						this.currentLanguage,
						this.showAllMessagesToVerify,
						this.params,
						this.perPage,
						(this.currentPage - 1) * this.perPage
					);

					break;
			}

			return records;
		},
		async getPaginateLangres() {
			let records = [];
			records = await this.searchPaginateAction();
			// if (this.filter.trim() === '') {
			// 	records = await this.handlePaginateAction();
			// } else {
			// 	records = await this.searchPaginateAction();
			// }

			return records;
		},
		async loadLangresForms() {
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}
			let results = await getAllFormsContentForTranslation(
				this.currentLanguage,
				this.webForm,
				this.params,
				this.showAllMessagesToVerify,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
			if (this.Langres.length === 0) {
				this.newLangres.message = '';
				this.newLangres.translatedMessage = '';
			}
		},
		async searchForms() {
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}
			let results = await searchtAllFormsContentForTranslation(
				this.currentLanguage,
				this.webForm,
				this.currentForm,
				this.showAllMessagesToVerify,
				this.params,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;

			if (this.Langres.length === 0) {
				this.newLangres.message = '';
				this.newLangres.translatedMessage = '';
			}
		},
		async loadMenus() {
			// let results = await getAllMenusContentForTranslation(
			// 	this.currentLanguage,
			// 	this.showAllMessagesToVerify,
			// 	this.perPage,
			// 	(this.currentPage - 1) * this.perPage
			// );
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}
			let results = await searchAllMenusContentForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this.params,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
		},
		async searchMenus() {
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}
			let results = await searchAllMenusContentForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this.params,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
		},
		async loadMessages() {
			let results = await getAllMessagesForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
		},
		async searchMessages() {
			if (this.showAIKeywords) {
				this.params = {
					...this._useParamsItems,
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes
				};
			} else {
				this.params = {
					filter: this.filter,
					category: this.selectedCategory,
					currentForm: this.currentForm,
					numberRes: +this.numberRes,
					formatMessageForFilterCategory: {
						number: 0,
						value: null
					}
				};
			}
			let results = await searchAllMessagesForTranslation(
				this.currentLanguage,
				this.showAllMessagesToVerify,
				this.params,
				this.perPage,
				(this.currentPage - 1) * this.perPage
			);

			this.Langres = results;
		},
		async loadForms() {
			let results = await getAllFormsNameOnly(this.currentLanguage, this.webForm);

			this.Forms = results;
			this.Forms.unshift(Object.assign({}, this.allForms));

			for (let i = 0; i < this.Forms.length; i++) {
				this.Forms[i] = {
					...this.Forms[i],
					formName: `${this.Forms[i].formName} (${this.Forms[i].formNumber})`
				};
			}
		},
		async translateAll(language = null) {
			await translateAllItems(language);
		},
		async curLangresUpd() {
			this.isSubmitted = true;

			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			await addUpdLangResItem(
				this.newLangres.type,
				this.newLangres.number,
				this.newLangres.value,
				this.currentLanguage,
				this.newLangres.translatedMessage,
				this.newLangres.paramFloat,
				this.newLangres.priority
			).then((result) => {
				if (result) {
					if (this.stateMessageAction === 1) {
						if (this.currentChoice === 0 || this.currentChoice === 1) {
							if (
								parseInt(this.newLangres.number, 10) === parseInt(this.copyLangres.number, 10) &&
								parseInt(this.newLangres.value, 10) !== parseInt(this.copyLangres.value, 10)
							) {
								this.stateMessageAction = 0;
							}
						} else if (this.currentChoice === 2) {
							if (parseInt(this.newLangres.number, 10) !== parseInt(this.copyLangres.number, 10)) {
								this.stateMessageAction = 0;
							}
						}
					}

					if (this.stateMessageAction === 1) {
						this.Langres[this.currentTableIndex].translatedMessage = this.newLangres.translatedMessage;
						this.Langres[this.currentTableIndex].paramFloat = this.newLangres.paramFloat;
						this.Langres[this.currentTableIndex].number = this.newLangres.number;
						this.Langres[this.currentTableIndex].value = this.newLangres.value;
						this.Langres[this.currentTableIndex].priority = this.newLangres.priority;
						this.Langres[this.currentTableIndex].toCheck = false;

						this.createToastForMobile('Success', 'Message updated successfully', '');
					} else if (this.stateMessageAction === 0) {
						this.choiceChanged();

						this.createToastForMobile('Success', 'Message added successfully', '');

						this.newMessageInfo = {
							...this.newMessageInfo,
							message: result.message,
							number: result.number,
							value: result.value,
							priority: result.priority,
							factor: result.paramFloat
						};

						this.addedMessageAlert = true;
					}
					this.isSubmitted = false;
				}
			});
		},
		async saveKeywords() {
			const keyWords = [...this.keywords].sort((a, b) => a.index - b.index).map((item) => item.name);
			await addUpdLangResItem(
				LANG_RES_TYPE.keyWord,
				this.newLangres.number,
				this.newLangres.value,
				this.currentLanguage,
				this.newLangres.translatedMessage,
				this.newLangres.paramFloat,
				this.newLangres.priority,
				keyWords
			).then((result) => {
				this.warningKeyword = '';
			});
			this.selectedItem = null;
		},
		delItem(item) {
			this.$bvModal
				.msgBoxConfirm('Are you sure to remove this message ?', {
					title: 'Confirm',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					if (this.currentChoice === 0) {
						await deleteFormItem(item.formNumber, item.value, this.webForm)
							.then((result) => {
								if (result) {
									this.choiceChanged();
								}
							})
							.finally(() => {
								this.createToastForMobile('Success', 'Message removed successfully', '');
							});
					} else {
						await deleteLanguageItem(item.type, item.number, item.value)
							.then((result) => {
								if (result) {
									this.choiceChanged();
								}
							})
							.finally(() => {
								this.createToastForMobile('Success', 'Message removed successfully', '');
							});
					}
				})
				.catch((err) => console.log(err));
		},
		async rowClicked(item, index, e) {
			this.currentTableIndex = !isNil(index) ? index : 0;

			this.stateMessageAction = 1;
			this.curLangres = item;
			this.copyLangres = Object.assign({}, item);
			this.newLangres = Object.assign({}, item);

			if (this.currentChoice === LANG_RES_TYPE.menu) {
				const res = await getAIKeywords(this.currentLanguage, +item.number, +item.value);
				this.keywords = res.keywords.filter((item) => item !== '').map((item, index) => ({ name: item, index: index + 1 }));
				this.references = [...res.keywordsRef].filter((item) => item !== '').map((item, index) => ({ name: item, index: index + 1 }));
				this.keywordsFilter = [...res.keywords].filter((item) => item !== '').map((item, index) => ({ name: item, index: index + 1 }));
				this.keywordsTemp = [...res.keywords].filter((item) => item !== '').map((item, index) => ({ name: item, index: index + 1 }));
				this.mapKeywordList();
			} else {
				this.keywords = [];
				this.keywordsTemp = [];
			}
		},
		calcManualTranslationCols(choice) {
			switch (choice) {
				case 0:
					this.numberCols = 6;
					this.valueCols = 6;
					this.factorCols = 0;
					this.helpCols = 0;

					break;
				case 1:
					this.numberCols = 3;
					this.valueCols = 3;
					this.factorCols = 2;
					this.priorityCols = 2;
					this.helpCols = 2;

					break;
				case 2:
					this.numberCols = 12;
					this.valueCols = 0;
					this.factorCols = 0;
					this.helpCols = 0;

					break;
			}
		},
		selectGoogleTranslation(e) {
			this.valueGoogleSelected = e.length > 1 ? e.splice(e.length - 1, 1) : e;
		},
		setTotalRows(length) {
			this.totalRows = length;
		},
		selectRow(idx = 0) {
			this.$refs.myTable.selectRow(idx);
		},
		unselectRow(idx = 0) {
			this.$refs.myTable.unselectRow(idx);
		},
		setDefaultRow() {
			if (this.Langres.length > 0) {
				this.selectRow();
				this.rowClicked(this.Langres[0]);
			}
		},
		onPaginated(payload) {
			this.currentPage = payload;
			this.pageNumber = 0;
			this.isBusyAllScreen = false;

			this.$nextTick(async () => {
				await this.getPaginateLangres()
					.then((records) => {
						if (records) {
							this.Langres = records;
						}
					})
					.catch((e) => {
						console.error({ e });
					})
					.finally(() => {
						this.setDefaultRow();
					});
			});
		},
		clearFilter() {
			const text = this.filter.trim();

			if (text.length > 0 && this.searchSubmitted) {
				this.choiceChanged();
			} else {
				this.filter = '';
				this.choiceChanged();
			}
		},
		removeFilter() {
			if (this.filter === '' && this.searchSubmitted) {
				this.choiceChanged();
			}
		},
		async search(e) {
			const removeSpaceQuote = /[\s'\/]+/g;
			const copyFilter = this.filter;
			this.filter = this.filter.trim().replace(removeSpaceQuote, '%');

			this.pageNumber = 0;

			if (this.filter === '') {
				return;
			}
			this.searchAction(copyFilter);
			this.searchSubmitted = true;
		},
		doTranslation(payload) {
			const translation = payload[0];

			this.$bvModal
				.msgBoxConfirm('Are you really sure to make translation ?', {
					title: 'Confirm',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) {
						this.valueGoogleSelected = [];

						return;
					}
					this.isBusyAllScreen = true;

					if (translation === 'all') {
						await this.translateAll()
							.then(() => {
								this.isBusyAllScreen = false;
							})
							.catch((e) => {
								console.error({ e });
								this.isBusyAllScreen = false;
								this.createToastForMobile(
									'Error',
									'An error was occured during google translation, please contact your administrator.',
									'',
									'danger'
								);
							})
							.finally(() => {
								this.choiceChanged();
								this.createToastForMobile('Success', 'Translation done successfully', '');
							});
					} else {
						await translateLanguageItems(parseInt(translation, 10))
							.then(() => {
								this.isBusyAllScreen = false;
							})
							.catch((e) => {
								console.error({ e });
								this.isBusyAllScreen = false;
								this.createToastForMobile(
									'Error',
									'An error was occured during google translation, please contact your administrator.',
									'',
									'danger'
								);
							})
							.finally(() => {
								this.choiceChanged();
								this.createToastForMobile('Success', 'Translation done successfully', '');
							});
					}
				})
				.catch((err) => {
					console.log(err);
					this.isBusyAllScreen = false;
				});
		},
		changeNumberRows() {
			this.choiceChanged();
		},
		newMessage() {
			this.stateMessageAction = 0;
			this.unselectRow();

			this.newLangres.type = this.langresType;
			this.newLangres.number = 0;
			this.newLangres.value = 0;
			this.newLangres.priority = 0;
			this.newLangres.translatedMessage = '';
			this.newLangres.message = '';
			this.newLangres.paramFloat = 0;
		},
		getShowAllMessagesToVerify(e) {
			this.choiceChanged();
		},
		async updateToCheckStatus(item, payload) {
			await updateLanguageCheckStatus(item.id, payload);
		},
		goToPage(e) {
			if (this.pageNumber > 0) {
				this.currentPage = this.pageNumber;
				this.handleAction(this.pageNumber);
			}
		},
		closeAlert() {
			this.addedMessageAlert = false;
		},
		getIconLucide(name) {
			return icons[name];
		},
		getShowAIkeywords(e) {
			if (e) {
				this.disableMenuNumber = true;
				this.numberRes = 0;
			} else {
				this.disableMenuNumber = false;
				this.selectedCategory = null;
			}
		},
		sleeperActiveRowKeyWord() {
			const execSleeper = () => {
				const keyWordTable = this.$refs['translation-key-word-table'];
				if (keyWordTable) {
					const trElements = keyWordTable.$el.querySelectorAll('tbody tr');
					setTimeout(() => {
						for (let i = 0; i < trElements.length; i++) {
							const checkSelected = trElements[i].className.toString().includes('b-table-row-selected table-primary');
							if (checkSelected) {
								const index = i;
								this.selectedItem = this.keywordList[index];
								break;
							} else {
								this.selectedItem = null;
							}
						}
					}, 100);
				}
			};
			return execSleeper();
		},
		keywordRowClicked(item) {
			this.sleeperActiveRowKeyWord();
			// const { index } = item;
			// if (this.selectedItem !== null && index === this.selectedItem.index) {
			// 	this.selectedItem = null;
			// } else {
			// 	this.selectedItem = item;
			// }
		},
		removeKeyword(unique) {
			const data = this.keywordList.map((item) =>
				!item.reference.includes(generateSecureId(this.textUniqueID)) ? { ...item, fromDB: true } : { ...item, fromDB: false }
			);
			const obj = data.find((item) => item.index === unique.index && item.reference === unique.reference && item.keyword === unique.keyword);
			const onDeleteRow = () => {
				this.keywordList = this.keywordList.filter((item) => item.index !== unique.index);
				this.references = [...this.references].filter((item) => item.index !== unique.index);
				this.keywordsFilter = [...this.keywordsFilter].filter((item) => item.index !== unique.index);
				this.keywordsTemp = [...this.keywordsTemp].filter((item) => item.index !== unique.index);
				this.keywords = [...this.keywords].filter((item) => item.index !== unique.index);
				this.warningKeyword = "Don't forget to save your changes";
				this.selectedItem = null;
			};
			if (obj.fromDB) {
				this.$bvModal
					.msgBoxConfirm('Are you sure to remove this keyword ?', {
						title: 'Confirm',
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: 'YES',
						cancelTitle: 'NO',
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (!v) return;
						onDeleteRow();
					})
					.catch((err) => console.log(err));
			} else {
				onDeleteRow();
			}
		},
		onClose() {
			this.popoverShow = false;
			this.sleeperActiveRowKeyWord();
		},
		onAddKeyWord(keyWords, itemSelect) {
			const onIsEnglish = () => {
				if (itemSelect !== null) {
					const { index } = itemSelect;
					const selectedIndex = index;
					this.keywordsFilter = [...this.keywordsFilter].map((item) => {
						if (item.index === selectedIndex) {
							return { ...item, name: keyWords };
						}
						return item;
					});
					this.keywordsTemp = [...this.keywordsTemp].map((item) => {
						if (item.index === selectedIndex) {
							return { ...item, name: keyWords };
						}
						return item;
					});
					this.keywords = [...this.keywords].map((item) => {
						if (item.index === selectedIndex) {
							return { ...item, name: keyWords };
						}
						return item;
					});
					this.keywordList = this.keywordList.map((item) => {
						if (item.index === selectedIndex) {
							return { ...item, keyword: keyWords };
						}
						return { ...item };
					});
					this.selectedItem = null;
				} else {
					if (!_.isNil(keyWords)) {
						this.references = [{ index: this.references.length + 1, name: keyWords }, ...this.references];
						this.keywordsFilter = [{ index: this.keywordsFilter.length + 1, name: keyWords }, ...this.keywordsFilter];
						this.keywordsTemp = [{ index: this.keywordsTemp, name: keyWords }, ...this.keywordsTemp];
						this.keywords = [{ index: this.keywords.length + 1, name: keyWords }, ...this.keywords];
						this.keywordList = [
							{
								keyword: keyWords,
								index: (this.keywordList.length || 0) + 1,
								fromDB: false,
								reference: `${generateSecureId(this.textUniqueID) + Math.random()}`
							},
							...this.keywordList
						];
					}
				}
				this.warningKeyword = "Don't forget to save your changes";
				this.selectedItem = null;
				this.onClose();
			};
			const onIsNotEnglish = () => {
				if (itemSelect !== null) {
					const { index } = itemSelect;
					const selectedIndex = index;
					const isItemKeyFilter = [...this.keywords].find((item) => item.index === selectedIndex);
					if (isItemKeyFilter !== undefined) {
						this.keywordsFilter = [...this.keywordsFilter].map((item) => {
							if (item.index === selectedIndex) {
								return { ...item, name: keyWords };
							}
							return item;
						});
					} else {
						this.keywordsFilter = [{ index: this.keywordsFilter.length + 1, name: keyWords }, ...this.keywordsFilter];
					}
					const isItemKeyTemp = [...this.keywords].find((item) => item.index === selectedIndex);
					if (isItemKeyTemp !== undefined) {
						this.keywordsTemp = [...this.keywordsTemp].map((item) => {
							if (item.index === selectedIndex) {
								return { ...item, name: keyWords };
							}
							return item;
						});
					} else {
						this.keywordsTemp = [{ index: this.keywordsTemp.length + 1, name: keyWords }, ...this.keywordsTemp];
					}
					const isItemKeyWord = [...this.keywords].find((item) => item.index === selectedIndex);
					if (isItemKeyWord !== undefined) {
						this.keywords = [...this.keywords].map((item) => {
							if (item.index === selectedIndex) {
								return { ...item, name: keyWords };
							}
							return item;
						});
					} else {
						this.keywords = [{ index: this.keywords.length + 1, name: keyWords }, ...this.keywords];
					}
					this.keywordList = this.keywordList.map((item) => {
						if (item.index === selectedIndex) {
							return { ...item, keyword: keyWords };
						}
						return { ...item };
					});
					this.selectedItem = null;
				} else {
					this.keywordsFilter = [{ index: this.keywordsFilter.length + 1, name: keyWords }, ...this.keywordsFilter];
					this.keywordsTemp = [{ index: this.keywordsTemp, name: keyWords }, ...this.keywordsTemp];
					this.keywords = [{ index: this.keywords.length + 1, name: keyWords }, ...this.keywords];
					this.keywordList = [
						{
							keyword: keyWords,
							index: (this.keywordList.length || 0) + 1,
							fromDB: false,
							reference: `${generateSecureId(this.textUniqueID) + Math.random()}`
						},
						...this.keywordList
					];
					this.selectedItem = null;
				}

				this.warningKeyword = "Don't forget to save your changes";
				this.onClose();
			};
			if (!this.isInEnglish) {
				onIsNotEnglish();
			} else {
				onIsEnglish();
			}
		}
	},
	async created() {
		await this.loadForms();
	},
	mounted() {
		this.$nextTick(async () => {
			this.isBusyAllScreen = true;
			this.calcManualTranslationCols(0);
			await getCarbonCategoryWithKeywords(this.currentLanguage).then((res) => {
				this.carbonListWithReference = res;
			});
			await this.loadLangresForms()
				.then(async () => {
					this.setTotalRows(await this.getTotalRowsLangres());
				})
				.catch((e) => {
					console.error({ e });
				})
				.finally(() => {
					this.setDefaultRow();
					this.isBusyAllScreen = false;
				});
		});
	},
	validations: {
		newLangres: {
			translatedMessage: {
				required
			}
		}
	},
	watch: {
		numberRes: {
			async handler(newVal) {
				if (newVal === 0) {
					const option = {
						message: null,
						number: newVal,
						value: null
					};
					this.handleSelectedOption(option);
					// this.currentForm = { ...this.currentForm, formNumber: newVal };
					this.setTotalRows(await this.getTotalRowsLangresOfSearch());
				}
				return newVal;
			}
		},
		selectedCategory: {
			async handler(newVal) {
				if (newVal === null) {
					const option = {
						message: null,
						number: null,
						value: null
					};
					this.handleSelectedOption(option);
					this.setTotalRows(await this.getTotalRowsLangresOfSearch());
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		keywordList: {
			handler(newVal) {
				if (newVal.length !== 0) {
					const data = newVal.map((item, i) =>
						item.reference.length !== 0 ? { ...item, index: i + 1, fromDB: true } : { ...item, index: i + 1, fromDB: false }
					);
					return data;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		warningKeyword: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	}
};
</script>
